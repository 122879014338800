export default [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    customRender: (value, item, index) => {
      return index + 1
    },
  },
  {
    title: '库位编号',
    dataIndex: 'number',
    sorter: true,
  },
  {
    title: '仓库',
    dataIndex: 'warehouse_name',
  },
  {
    title: '库区',
    dataIndex: 'reservoir_area_name',
  },
  {
    title: '货架及地面区域号',
    dataIndex: 'row_number',
  },
  {
    title: '行号',
    dataIndex: 'layer_number',
  },
  {
    title: '列号',
    dataIndex: 'column_number',
  },
  {
    title: '备注',
    dataIndex: 'remark',
  },
  {
    title: '状态',
    dataIndex: 'is_active',
    scopedSlots: { customRender: 'is_active' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    width: '216px'
  },
]